import screenfull from 'screenfull';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-header-another',
  templateUrl: './header-another.component.html',
  providers: [MenuService]
})
export class HeaderAnotherComponent implements OnInit {
  @Output()
  toggleSidenav = new EventEmitter<any>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();
  userName;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //console.log('open trigger');
    this.toggleSidenav.emit('open');
  }

  constructor(
    public _common: CommonService,
    public menuService: MenuService,
    private router: Router,
    private authService: AuthService
  ) {
    //console.log(this.router.url) // dataset/list

    /* let user = JSON.parse(localStorage.getItem('currentUser'));
    let getAllmenu = menuService.getAll(); */

    //console.log(getAllmenu);

    /* if (this._common._auth.isAnnotator()) {
      menuService.getAll().forEach((ele) => {
        if (ele.state == this.router.url) {
          console.log(ele)
        } else if (this.router.url == "/maindashboard") {
          this._common._router.navigateByUrl("/task/list");
        } else{
          this._common._router.navigateByUrl("/task/list");
        }
      })
    } */

    /* menuService.getAll().forEach((ele)=>{
      if(ele.state == this.router.url){
        ele.allowed_roles.forEach((element) => {
          if(element == user.role){
            console.log(element, user.role, ele.state)
            this._common._router.navigateByUrl(ele.state);
          }
        })
      }
    }) */
  }

  ngOnInit(): void {
    let getAllmenu = this.menuService.getAll();
    //console.log(getAllmenu);

    if (this._common._auth.isAnnotator()) {
      this.menuService.getAll().forEach((ele) => {
        if (ele.state == this.router.url) {
          console.log(ele)
        } else if (this.router.url == "/maindashboard") {
          this.router.navigate(['/task/list']);
          //this._common._router.navigateByUrl("/task/list");
        } else {
          this.router.navigate(['/task/list']);
        }
      })
    }


    //console.log(localStorage.getItem('currentUser'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.name) {
      this.userName = currentUser.name;
    } else {
      this.userName = '';
    }
  }

  getClickNavigate() {
    //console.log(this.router.url)
    //this.authService.getEntryUrl();

    let user = JSON.parse(localStorage.getItem('currentUser'));
    //console.log(user.role)
    if (user.role == 'admin') {
      this._common._router.navigateByUrl('/maindashboard');
    } else if (user.role == 'manager') {
      this._common._router.navigateByUrl('/maindashboard');
    } else if (user.role == 'datascientist') {
      this._common._router.navigateByUrl('/maindashboard');
    } else if (user.role == 'annotator') {
      this._common._router.navigateByUrl('/task/list');
    } else {
      this._common._router.navigateByUrl('/admin/dashboard');
    }



  }

  fullScreenToggle(): void {
    if (screenfull && screenfull.isEnabled) {
      screenfull.toggle();
      if (screenfull.isFullscreen) {
        console.log('open trigger');
        this.toggleSidenav.emit('open');
      } else {
        console.log('close trigger');
        this.toggleSidenav.emit('close');
      }
    }
  }

  logout() {
    this._common._api.post(this._common._api.ApiUrls().getLogout, {}).subscribe((response) => {
      this._common._alert.showAlert('Successfully Logged Out', 'Success');
      this._common._auth.logout();
      this._common._router.navigateByUrl('/login');
    }, (error) => {
      this._common._alert.showAlert('Something went wrong', 'Error');
    });
  }
}
