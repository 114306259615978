import { Component, Input, OnInit, ViewChild } from '@angular/core';
//import { ChartDataSets, ChartOptions } from 'chart.js';
//import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})

export class GraphComponent implements OnInit {
  @Input('experiment_id') experiment_id;
  @Input('train_request_id') train_request_id;

  /* public lineChartData: ChartDataSets[] = [

    { data: [], label: 'Loss', yAxisID: 'y-axis-1' }
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [

        {
          id: 'y-axis-1',
          position: 'left',
          gridLines: {
            color: 'rgb(51, 204, 255)',
          },
          ticks: {
            fontColor: 'rgb(51, 204, 255)',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'rgb(51, 204, 255)',
          borderWidth: 1,
          label: {
            enabled: true,
            fontColor: 'rgb(51, 204, 255)',

          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [

    { // red
      backgroundColor: 'rgb(51, 204, 255)',
      borderColor: 'rgb(51, 204, 255)',
      pointBackgroundColor: 'rgb(51, 204, 255)',
      pointBorderColor: '#33ccff',
      pointHoverBackgroundColor: '#33ccff',
      pointHoverBorderColor: 'rgb(51, 204, 255)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  trainingInProgress = false;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public _common: CommonService) {
    this._common._socket.on('training.newStep').subscribe((data) => {
      const body = data.body;
      if (this.train_request_id === body.train_request_id && this.experiment_id === body.experiment_id) {
        this.trainingInProgress = true;
        this.lineChartLabels.push(body.log.step);
        this.lineChartData[0].data.push(body.log.loss);
      }
    });
  } */

  ngOnInit() {
    // setInterval(() => {
    //   const d = new Date().toLocaleTimeString();
    //   this.lineChartLabels.shift();
    //   this.lineChartLabels.push(d);
    //   this.lineChartData[0].data.shift();
    //   this.lineChartData[0].data.push(this.generateNumber(3));
    // }, 1000);

    const d = new Date().toLocaleTimeString();
    /* this.lineChartLabels.shift();
    this.lineChartLabels.push(d);
    this.lineChartData[0].data.shift(); */

  }

  // public randomize(): void {
  //   for (let i = 0; i < this.lineChartData.length; i++) {
  //     for (let j = 0; j < this.lineChartData[i].data.length; j++) {
  //       this.lineChartData[i].data[j] = this.generateNumber(i);
  //     }
  //   }
  //   this.chart.update();
  // }

  // private generateNumber(i: number) {
  //   return Math.floor((Math.random() * (i < 2 ? 1 : 25)) + 1);
  // }


  // public hideOne() {
  //   const isHidden = this.chart.isDatasetHidden(1);
  //   this.chart.hideDataset(1, !isHidden);
  // }

  // public pushOne() {
  //   this.lineChartData.forEach((x, i) => {
  //     const num = this.generateNumber(i);
  //     const data: number[] = x.data as number[];
  //     data.push(num);
  //   });
  //   this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  // }

}
