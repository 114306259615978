import {
  AccordionAnchorDirective,
  AccordionDirective, AccordionLinkDirective,
  FooterComponent,
  HeaderComponent,
  MenuComponent,
  NotificationComponent,
  SidebarComponent
} from './core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {SharedModule} from '../shared/shared.module';
import {RouterLink, RouterModule} from '@angular/router';
import { LabsLayoutComponent } from './labs-layout/labs-layout.component';
import { HeaderAnotherComponent } from './core/header-another/header-another.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderAnotherComponent,
    FooterComponent,
    SidebarComponent,
    NotificationComponent,
    MenuComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    LabsLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    HeaderAnotherComponent,
    FooterComponent,
    SidebarComponent,
    NotificationComponent,
    MenuComponent,
    AppLayoutComponent,
    LabsLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
  ]
})
export class ThemeModule {
}
