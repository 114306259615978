<div class="app mat-typography" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <mat-toolbar class="main-header" color="white">
    <div class="branding">
      <div class="logo"></div>
    </div>
    <div class="search-bar" fxFlex>

    </div>
    <button (click)="fullScreenToggle()" mat-icon-button>
      <mat-icon color="teric-icon">fullscreen</mat-icon>
    </button>
    <button (click)="toggleNotificationSidenav.emit()" mat-icon-button class="ml-xs overflow-visible">
      <mat-icon color="teric-icon">notifications</mat-icon>
    </button>
    <button mat-button [matMenuTriggerFor]="user" class="user-menu-trigger-mobile">
      <mat-icon color="teric-icon">person</mat-icon>&nbsp;<span style="font-size: 15px;font-weight: 500;">{{userName |
        titlecase}}</span>&nbsp;<mat-icon color="teric-icon" style="color: #6f6b6b !important;">arrow_drop_down
      </mat-icon>
    </button>
    <mat-menu #user="matMenu" x-position="before">
      <button mat-menu-item routerLink="/settings">
        <mat-icon color="teric-icon">settings</mat-icon>
        Settings
      </button>
      <button mat-menu-item routerLink="/profile">
        <mat-icon color="teric-icon">account_box</mat-icon>
        Profile
      </button>
      <button mat-menu-item>
        <mat-icon color="teric-icon">notifications_off</mat-icon>
        Disable notifications
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon color="teric-icon">exit_to_app</mat-icon>
        Sign Out
      </button>
    </mat-menu>
  </mat-toolbar>
  <div class="app-inner">
    <mat-card class="no-color" style="width: 100%;">
      <!-- <mat-card-header fxLayout fxLayout.xs="row">
        <div fxFlex="50%">
          <mat-card-title class="main-title with-search">Dashboard</mat-card-title>
        </div>
        <div fxFlex="50%" class="txtalign mat-card-header-options">

        </div>
      </mat-card-header> -->
      <mat-card-content>
        <div class="content-wrapper pt-0">
          <div fxLayout="row wrap" style="padding: 10px;">
            <div fxFlex="20%" *ngFor="let num of [1,2,3,4,5,6,7,8,9,10,11]" fxLayoutAlign="center">
              <mat-card class="card">
                <img src="https://threejs.org/files/projects/github.png" (click)="onClickFunction(num)" title="Vision" alt="Vision" style="width:100%">
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>