import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class CustomIconsModule {
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('magic-wand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/magic-wand.svg')
    );
    this.matIconRegistry.addSvgIcon('hand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/hand.svg')
    );
    this.matIconRegistry.addSvgIcon('rectangle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/rectangle.svg')
    );
    this.matIconRegistry.addSvgIcon('circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/circle-outline.svg')
    );
    this.matIconRegistry.addSvgIcon('vector',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/vector.svg')
    );
    this.matIconRegistry.addSvgIcon('polygonal-apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/polygonal-apple-of-small-triangles.svg')
    );

    this.matIconRegistry.addSvgIcon('erasor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/erasor.svg')
    );

  }
}
