import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../shared/services/common.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent implements OnInit {
  threadId: String;
  commentsList = [];
  showAddComment = false;
  newComment = {msg: '', replayTo: null};
  @Output() commentAdded: EventEmitter<any> = new EventEmitter();

  constructor(private _common: CommonService) {
  }

  ngOnInit() {
  }

  getAllComments(page = 1) {
    this._common._api.post(this._common._api.ApiUrls().getListComment, {'thread_id': this.threadId}).subscribe((data) => {
      this.commentsList = data.data.docs || [];
    }, (err) => {
      console.log(err);
    });
  }

  addComment(msg, replayTo = null) {
    const payload = {'thread_id': this.threadId};
    payload['msg'] = msg;
    payload['replayTo'] = replayTo;

    this._common._api.post(this._common._api.ApiUrls().setAddComment, payload).subscribe((data) => {
      console.log(data);
      this.commentAdded.emit(data);
      this.getAllComments();
      this.openAddComment(false);
    }, (err) => {
      console.log(err);
    });
  }

  openAddComment(open = true) {
    this.newComment = {msg: '', replayTo: null};
    this.showAddComment = open;
    this.scrollToBottom('.comments-holder');
  }

  scrollToBottom(selector) {
    $(selector).animate({scrollTop: $(selector)[0].scrollHeight}, 200);
  }
}
