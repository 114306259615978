import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;
  token: string;
  aclModules = [];

  constructor(private router: Router) {
    const user = window.localStorage.getItem('currentUser');
    try {
      this.user = JSON.parse(user);
      /* console.log(this.user) */
    } catch (e) {
      // console.log(e);
      this.user = null;
    }
    this.token = window.localStorage.getItem('token');
  }

  afterLogin(data: any) {
    //console.log(data);
    //localStorage.setItem('zastraAuth', data);
    localStorage.setItem('token', data.token);
    localStorage.setItem('currentUser', JSON.stringify(data.user));
    this.token = data.token;
    this.user = data.user;
    this.aclModules = data.modules;
  }

  public getToken(): string {
    return window.localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    return !!this.token;
  }





  getEntryUrl() {
    console.log(this.user.role)
    switch (this.user.role) {
      case 'admin': {
        return '/maindashboard';
      }
      case 'manager': {
        return '/maindashboard';
      }
      case 'datascientist': {
        return '/maindashboard';
      }
      case 'annotator': {
        return '/task/list';
      }
    }
  }

  isManager() {
    return this.user.role === 'manager';
  }

  isDataScientist() {
    return this.user.role === 'datascientist';
  }

  isAnnotator() {
    return this.user.role === 'annotator';
  }

  isAdmin() {
    return this.user.role === 'admin';
  }

  public isRolesAuthenticated(): boolean {
    if (this.isAnnotator()) {
      this.router.navigate(['/homepage']);
      return true;
    } else {
      this.router.navigate(['/maindashboard']);
      return true;
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    delete this.user;
    delete this.token;
  }
}
