<ng-container>
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!isConnected">
  <div class="no-internet-access-mask-wrapper">
    <div class="no-internet-text">
      NO INTERNET
    </div>
  </div>
</ng-container>
