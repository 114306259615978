import { Injectable } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  allowed_roles?: Array<any>;
  modules?: Array<any>;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '/dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
    allowed_roles: ['admin', 'manager']
  },
  /*  {
     state: '/explore',
     name: 'Explore',
     type: 'link',
     icon: 'explore',
     allowed_roles: ['admin', 'datascientist', 'manager'],
     modules: ['explore']
   }, */
  {
    state: '/dataset/list',
    name: 'Datasets',
    type: 'link',
    icon: 'photo_library',
    allowed_roles: ['manager', 'datascientist'],
    modules: ['dataset']
  },
  {
    state: '/projects',
    name: 'Projects',
    type: 'link',
    icon: 'folder',
    allowed_roles: ['manager', 'datascientist'],
    modules: ['project']
  },
  {
    state: '/models',
    name: 'Trained Models',
    type: 'link',
    icon: 'extension',
    allowed_roles: ['manager', 'datascientist'],
    modules: ['models']
  },
  {
    state: '/user/list',
    name: 'Users',
    type: 'link',
    icon: 'person',
    allowed_roles: ['manager', 'datascientist'],
    modules: ['user']
  },
  {
    state: '/team/list',
    name: 'Teams',
    type: 'link',
    icon: 'group',
    allowed_roles: ['manager', 'admin'],
    modules: ['team']
  },
  {
    state: '/request/list',
    name: 'Annotation Requests',
    type: 'link',
    icon: 'toc',
    allowed_roles: ['manager'],
    modules: ['request']
  },
  {
    state: '/task/list',
    name: 'Assigned Tasks',
    type: 'link',
    icon: 'list',
    allowed_roles: ['manager', 'annotator'],
    modules: ['task1']
  },
  {
    state: '/roles/list',
    name: 'Permissions',
    type: 'link',
    icon: 'public',
    allowed_roles: ['manager', 'admin'],
    modules: ['team']
  },
  
  {
    state: '/labs/dashboard',
    name: 'Image generation',
    type: 'link',
    icon: 'image',
    allowed_roles: ['manager', 'admin'],
    modules: ['team']
  },
  {
    state: '/labs/text-generation',
    name: 'Text generation',
    type: 'link',
    icon: 'format_align_center',
    allowed_roles: ['manager', 'admin'],
    modules: ['team']
  },
  {
    state: '/labs/synthetic-data-generation',
    name: 'Synthetic data generation',
    type: 'link',
    icon: '3d_rotation',
    allowed_roles: ['manager', 'admin'],
    modules: ['team']
  } 

];

@Injectable()
export class MenuService {

  constructor(public _common: CommonService, private authService: AuthService, private router: Router) {
    //console.log(router.url);
  }

  getAll(): Menu[] {
    // return MENUITEMS;
    // return MENUITEMS.filter((menuitem) => {
    //   if (menuitem.allowed_roles.indexOf(this._common._auth.user.role) > -1) {
    //     return menuitem;
    //   } else { 
    //     return;
    //   }
    // });

    

    return MENUITEMS.filter((menuitem) => {
      if (this._common._auth.isAnnotator()) {
        if (menuitem.name == "Assigned Tasks" || menuitem.name != "Text generation" && menuitem.name != "Image generation" && menuitem.name != "Synthetic data generation") {
          return menuitem;
        }
      } else if (this._common._auth.isDataScientist()) {
        if (menuitem.name != "Annotation Requests" && menuitem.name != "Users" && menuitem.name != "Assigned Tasks" && menuitem.name != "Teams" && menuitem.name != "Text generation" && menuitem.name != "Image generation" && menuitem.name != "Synthetic data generation") {
          return menuitem;
        }
      } else if (this.router.url == "/labs/dashboard" || this.router.url == "/labs/text-generation"|| this.router.url == "/labs/synthetic-data-generation") {
        if ((menuitem.name == "Text generation") || (menuitem.name == "Image generation") || (menuitem.name == "Synthetic data generation")) {
          return menuitem;
        }
      } else {
        if ((menuitem.name != "Text generation") && (menuitem.name != "Image generation") && (menuitem.name != "Synthetic data generation")) {
          return menuitem;
        }
      }
    });
  }

  /* getMenu() {
    return MENUITEMS.filter((menuitem) => {
      if (this._common._auth.isAnnotator()) {
        if (menuitem.name == "Assigned Tasks") {
          return this.authService.user.role.haveElement(menuitem.allowed_roles);
        }
      } else if (this._common._auth.isDataScientist()) {
        if (menuitem.name != "Annotation Requests" && menuitem.name != "Users" && menuitem.name != "Assigned Tasks" && menuitem.name != "Teams") {
          return this.authService.user.role.haveElement(menuitem.allowed_roles);;
        }
      } else {
        return this.authService.user.role.haveElement(menuitem.allowed_roles);;
      }
    }); 
  }*/
}
