import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, EventEmitter, HostListener, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';

import screenfull from 'screenfull';
import { CommonService } from 'src/app/shared/services/common.service';
import { ServerService } from 'src/app/shared/services/server.service';
import { MenuService } from '../core/menu/menu.service';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-maindashboard',
  templateUrl: './maindashboard-layout.component.html',
  styleUrls: ['./maindashboard-layout.component.scss'],
  providers: [MenuService]
})
export class MainDashboardLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;



  mediaMatcher: MediaQueryList;
  url: string;
  sidePanelOpened;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  @ViewChild('sidemenu', { static: true }) sidemenu;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveScroll: PerfectScrollbarDirective;

  public config: PerfectScrollbarConfigInterface = {};
  userName: any;

  @Output()
  toggleSidenav = new EventEmitter<any>();

  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();

  constructor(
    private _server: ServerService,
    public _common: CommonService,
    private _element: ElementRef,
    private router: Router,
    public menuService: MenuService,
    zone: NgZone,
    private _mediaMatcher: MediaMatcher
  ) {
    this.mediaMatcher = matchMedia(
      `(max-width: ${SMALL_WIDTH_BREAKPOINT - 100}px )`
    );

    //this._server.checkLogin();
  }

  openSideNav(open) {
    if (open === 'toggle') {
      this.sidemenu.toggle();
    } else if (open === 'open') {
      this.sidemenu.open();
    } else {
      this.sidemenu.close();
    }
  }

  ngOnInit(): void {

    if (this._common._auth.isAnnotator()) {
      this.menuService.getAll().forEach((ele) => {
        if (ele.state == this.router.url) {
          console.log(ele)
        } else if (this.router.url == "/maindashboard") {
          this.router.navigate(['/task/list']);
          //this._common._router.navigateByUrl("/task/list");
        } else {
          this.router.navigate(['/task/list']);
        }
      })
    }


    /* if (this.router.url === '/maindashboard') {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      console.log(user.role)
      if(user.role == 'admin'){
        this._common._router.navigateByUrl('/maindashboard');
      }else if(user.role == 'manager'){
        this._common._router.navigateByUrl('/maindashboard');
      }else if(user.role == 'datascientist'){
        this._common._router.navigateByUrl('/maindashboard');
      }else if(user.role == 'annotator'){
        this._common._router.navigateByUrl('/task/list');
      }
    } */

    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        document.querySelector(
          '.app-inner > .mat-drawer-content > div'
        ).scrollTop = 0;
        this.url = event.url;
        this.runOnRouteChange();
      });

    //console.log(localStorage.getItem('currentUser'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.name) {
      this.userName = currentUser.name;
    } else {
      this.userName = '';
    }
  }

  onClickFunction(event) {
    if (event == "vision") {
      //this._common._router.navigateByUrl(this._common._auth.getEntryUrl());
      this._common._router.navigateByUrl('/dashboard');
    } else if (event == "labs") {
      this._common._router.navigateByUrl('/labs/dashboard');
    } else {
      this._common._alert.showAlert('Coming Soon...', '');
    }
  }

  fullScreenToggle(): void {
    if (screenfull && screenfull.isEnabled) {
      screenfull.toggle();
      if (screenfull.isFullscreen) {
        console.log('open trigger');
        this.toggleSidenav.emit('open');
      } else {
        console.log('close trigger');
        this.toggleSidenav.emit('close');
      }
    }
  }

  logout() {
    this._common._api.post(this._common._api.ApiUrls().getLogout, {}).subscribe((response) => {
      this._common._alert.showAlert('Successfully Logged Out', 'Success');
      this._common._auth.logout();
      this._common._router.navigateByUrl('/login');
    }, (error) => {
      this._common._alert.showAlert('Something went wrong', 'Error');
    });
  }

  ngOnDestroy(): void {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }

    // if (this.isCollapsed()) {
    //   //
    // }

    this.updatePS();
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages' ||
      this.url === '/apps/calendar' ||
      this.url === '/apps/media' ||
      this.url === '/maps/leaflet' ||
      this.url === '/taskboard'
      // ||
      // this.url.indexOf('/explore-inference') >= 0
    ) {
      return true;
    } else {
      return this.mediaMatcher.matches;
    }
  }

  // isCollapsed(): boolean {
  //   if (
  //     this.url.indexOf('/explore-inference') >= 0
  //   ) {
  //     return true;
  //   } else {
  //     return this.mediaMatcher.matches;
  //   }
  // }

  menuMouseOver(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'side';
    }
  }

  updatePS(): void {
    if (!this.mediaMatcher.matches && !this.options.compact) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }
}
