<div class="app mat-typography" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <app-header-another></app-header-another>
  <mat-sidenav-container class="app-inner">
    
    <div [perfectScrollbar]="config" [disabled]="mediaMatcher.matches">
      <!-- <app-content-header></app-content-header> -->
   
      <router-outlet></router-outlet>
      <!--      <hr>-->
      <!-- <app-footer></app-footer> -->
    </div>
  </mat-sidenav-container>
  <!--  <app-options (messageEvent)="receiveOptions($event)"></app-options>-->

</div>