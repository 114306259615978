import {Component} from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  today: number = Date.now();

  constructor() {
  }
}
