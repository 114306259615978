<div class="app mat-typography" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <mat-toolbar class="main-header" color="white">
    <div class="branding">
      <div class="logo"></div>
    </div>
    <div class="search-bar" fxFlex>

    </div>
    <button (click)="fullScreenToggle()" mat-icon-button>
      <mat-icon color="teric-icon">fullscreen</mat-icon>
    </button>
    <button (click)="toggleNotificationSidenav.emit()" mat-icon-button class="ml-xs overflow-visible">
      <mat-icon color="teric-icon">notifications</mat-icon>
    </button>
    <button mat-button [matMenuTriggerFor]="user" class="user-menu-trigger-mobile">
      <mat-icon color="teric-icon">person</mat-icon>&nbsp;<span
        style="font-size: 15px;font-weight: 500;">{{userName | titlecase}}</span>&nbsp;<mat-icon color="teric-icon" style="color: #6f6b6b !important;">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #user="matMenu" x-position="before">
      <button mat-menu-item routerLink="/settings">
        <mat-icon color="teric-icon">settings</mat-icon>
        Settings
      </button>
      <button mat-menu-item routerLink="/profile">
        <mat-icon color="teric-icon">account_box</mat-icon>
        Profile
      </button>
      <button mat-menu-item>
        <mat-icon color="teric-icon">notifications_off</mat-icon>
        Disable notifications
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon color="teric-icon">exit_to_app</mat-icon>
        Sign Out
      </button>
    </mat-menu>
  </mat-toolbar>
  <div class="app-inner">
    <mat-card class="no-color" class="w-100">
      <mat-card-header class="position-absolute p-4 w-100" fxLayout fxLayout.xs="row">
        <div fxFlex="50%">
          <mat-card-title class="main-title with-search mb-0 font-weight-bold">Dashboard</mat-card-title>
        </div>
        <div fxFlex="50%" class="txtalign mat-card-header-options"></div>
      </mat-card-header>
      <mat-card-content>
        <div class="content-wrapper">
          <div class="icon-center w-100" style="padding-bottom: 40px;" fxLayout="row" fxFlex="100%">
            <div fxFlex="30" fxLayoutAlign="center">
              <mat-card class="card visionCard">
                <img src="assets/layout/images/vision.svg" title="Vision" alt="Vision" class="w-100"
                  (click)="onClickFunction('vision')">
                <div class="dashTitle">Vision</div>
              </mat-card>
            </div>
            <div fxFlex="30" fxLayoutAlign="center">
              <mat-card class="card textCard">
                <img src="assets/layout/images/text1.svg" title="Text" alt="Text" class="w-100"
                  (click)="onClickFunction('text')">
                <div class="dashTitle">Text</div>
              </mat-card>
            </div>
            <div fxFlex="30" fxLayoutAlign="center">
              <mat-card class="card speechCard">
                <img src="assets/layout/images/microphone2.svg" title="Audio" alt="Audio" class="w-100"
                  (click)="onClickFunction('audio')">
                <div class="dashTitle">Audio</div>
              </mat-card>
            </div>
            <div fxFlex="30" fxLayoutAlign="center">
              <mat-card class="card speechCard">
                <img style="height: 177px;object-fit: cover;" src="assets/layout/images/labs.jpeg" title="labs" alt="labs" class="w-100"
                  (click)="onClickFunction('labs')">
                <div class="dashTitle">Zastra Labs</div>
              </mat-card>
            </div>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>