import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoordsOptimiserService {

  constructor(

  ) {

  }

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

// square distance between 2 points
  getSqDist(p1, p2) {

    p1.x = p1[0];
    p1.y = p1[1];
    p2.x = p2[0];
    p2.y = p2[1];

    const dx = p1.x - p2.x,
      dy = p1.y - p2.y;

    return dx * dx + dy * dy;
  }

// square distance from a point to a segment
  getSqSegDist(p, p1, p2) {

    p1.x = p1[0];
    p1.y = p1[1];
    p2.x = p2[0];
    p2.y = p2[1];

    let x = p1.x,
      y = p1.y,
      dx = p2.x - x,
      dy = p2.y - y;

    if (dx !== 0 || dy !== 0) {

      let t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);

      if (t > 1) {
        x = p2.x;
        y = p2.y;

      } else if (t > 0) {
        x += dx * t;
        y += dy * t;
      }
    }

    dx = p.x - x;
    dy = p.y - y;

    return dx * dx + dy * dy;
  }
// rest of the code doesn't care about point format

// basic distance-based simplification
  simplifyRadialDist(points, sqTolerance) {

    let prevPoint = points[0],
      newPoints = [prevPoint],
      point;

    for (var i = 1, len = points.length; i < len; i++) {
      point = points[i];

      if (this.getSqDist(point, prevPoint) > sqTolerance) {
        newPoints.push(point);
        prevPoint = point;
      }
    }

    if (prevPoint !== point) newPoints.push(point);

    return newPoints;
  }

  simplifyDPStep(points, first, last, sqTolerance, simplified) {
    let maxSqDist = sqTolerance,
      index;

    for (let i = first + 1; i < last; i++) {
      let sqDist = this.getSqSegDist(points[i], points[first], points[last]);

      if (sqDist > maxSqDist) {
        index = i;
        maxSqDist = sqDist;
      }
    }

    if (maxSqDist > sqTolerance) {
      if (index - first > 1) {
        this.simplifyDPStep(points, first, index, sqTolerance, simplified);
      }
      simplified.push(points[index]);
      if (last - index > 1) {
        this.simplifyDPStep(points, index, last, sqTolerance, simplified);
      }
    }
  }

// simplification using Ramer-Douglas-Peucker algorithm
  simplifyDouglasPeucker(points, sqTolerance) {
    let last = points.length - 1;

    let simplified = [points[0]];
    this.simplifyDPStep(points, 0, last, sqTolerance, simplified);
    simplified.push(points[last]);

    return simplified;
  }

// both algorithms combined for awesome performance
  simplify(points, tolerance, highestQuality) {

    if (points.length <= 2) { return points; }

    const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

    points = highestQuality ? points : this.simplifyRadialDist(points, sqTolerance);
    points = this.simplifyDouglasPeucker(points, sqTolerance);

    return points;
  }

}
