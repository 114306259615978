import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommentsListComponent} from './comments-list/comments-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CommentsListComponent
  ],
  imports: [CommonModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule, MatInputModule, MatButtonModule],
  exports: [CommentsListComponent]
})
export class CommentWrapperModule {
}
