<div class="comments-holder">
  <div class="comment-item"
       *ngFor="let comment of commentsList; let i = index">
    <div class="comment-item-time">
      {{comment.created_at | date:'medium' }}
    </div>
    <div class="comment-item-icon">
      {{comment.user.name }}
    </div>
    <div class="comment-item-text">
      {{comment.body}}
    </div>
  </div>
  <div class="add-new-comment-holder" *ngIf="showAddComment">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>New Comment</mat-label>
      <input matInput type="text" placeholder="enter comment here" [(ngModel)]="newComment.msg">
    </mat-form-field>
    <div class="input-group-append">
      <button mat-flat-button color="primary" [disabled]="newComment.msg ==''"
              (click)="addComment(newComment.msg, newComment.replayTo)">
        <mat-icon>check</mat-icon>
      </button>
      <button mat-flat-button color="accent" (click)="openAddComment(false)">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
