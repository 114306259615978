<div class="clearfix"></div>
<div class=" mat-card no-color">
  <div class="mat-card-header">
    <div class="main-title mat-card-title">Live Training Status</div>
  </div>
  <div class="ts-clearfix">&nbsp;</div>
  <div class="mat-card-content">
    <div class="ts-clearfix"></div>
    <div class="mat-white">
      <!-- <canvas baseChart width="1000" height="300" [datasets]="lineChartData" [labels]="lineChartLabels"
        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
        [plugins]="lineChartPlugins"></canvas> -->
    </div>
  </div>
</div>