import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollSpyModule } from 'ngx-scrollspy';
import { LandLayoutComponent } from './layout.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LandLayoutComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    //NgbModule,
    ScrollSpyModule.forRoot(),
    ScrollToModule.forRoot()
  ]
})
export class LandLayoutModule { }
