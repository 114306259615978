<mat-nav-list appAccordion class="navigation" [perfectScrollbar]="config">
  <ng-container *ngFor="let menuitem of menuService.getAll()">
    <mat-list-item appAccordionLink group="{{menuitem.state}}"> 
      <a appAccordionToggle class="relative" routerLink="{{menuitem.state}}" *ngIf="menuitem.type === 'link'">
        <mat-icon color="teric-icon">{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
        <mat-icon color="teric-icon">{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="{{menuitem.state}}" target="_blank"
         *ngIf="menuitem.type === 'extTabLink'">
        <mat-icon color="teric-icon">{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="javascript:;" *ngIf="menuitem.type === 'sub'">
        <mat-icon color="teric-icon">{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
      </a>
      <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
        <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
          <a [routerLink]="['/', menuitem.state, childitem.state ]"
             class="relative">{{ childitem.name }}</a>
        </mat-list-item>
      </mat-nav-list>
    </mat-list-item>
  </ng-container>
</mat-nav-list>


<!--*ngIf="menuitem.allowedroles.indexOf(menuService.user.role)>-1"-->
