import {Component, OnInit} from '@angular/core';
import {ServerService} from './shared/services/server.service';
import {NetworkStatusAngularService} from 'network-status-angular';
import {SocketService} from './shared/services/socket.service';
import {WebSocketsService} from './shared/services/web-sockets.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  status = 'ONLINE';
  isConnected = true;

  constructor(private _server: ServerService,
              private connectionService: NetworkStatusAngularService,
              public _socket: WebSocketsService) {

    this._server.checkLogin((data) => {
      if (data && data.success) {
        this._socket.on('handshake.success').subscribe((connected) => {
          if (connected) {
            this._socket.connect();
          }
        });
      }
    });

    this.connectionService.status.subscribe(status => {
      console.log(status); // returns true if it is online or false if it is offline
      this.isConnected = status;
      if (this.isConnected) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
      }
    });
  }

  ngOnInit(): void {

  }
}
