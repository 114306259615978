import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CommonService} from '../services/common.service';

@Injectable()
export class MainAuthGuard implements CanActivate {

  returnUrl: string;

  constructor(private _common: CommonService, private _route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    //this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    //console.log(this.returnUrl);
    if (this._common._auth.isAuthenticated()) {
      /* const user = JSON.parse(localStorage.getItem('currentUser'));
      if(user.role == 'annotator'){
        this._common._router.navigate(['/task/list']);
      }else{
        this._common._router.navigate(['/']);
      } */
      return true;
    } else {
      this._common._router.navigate(['/homepage']);
      return false;
    }
  }
}
