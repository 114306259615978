import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NonAuthGuard } from './shared/guards/non-auth.guard';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LandLayoutComponent } from './layout/land-layout/layout/layout.component';
import { MainDashboardLayoutComponent } from './layout/maindashboard-layout/maindashboard-layout.component';
import { MainAuthGuard } from './shared/guards/main-auth.guard';
import { ThreeDModelListComponent } from './layout/threedmodel-layout/3dmodel-list.component';
import { ThreeDModelViewerComponent } from './layout/threedmodel-layout/3dmodel-viewer/3dmodel-viewer.component';
import { LabsLayoutComponent } from './layout/labs-layout/labs-layout.component';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/maindashboard', pathMatch: 'full' },
  { 
    path: '', component: LandLayoutComponent, 
    loadChildren: () => import('./landpages/landpages.module').then(m => m.LandPagesModule), 
    canActivate: [NonAuthGuard] 
  },
  {
    path: 'maindashboard', component: MainDashboardLayoutComponent,
    canActivate: [MainAuthGuard],
    
  },

  {
    path: 'labs', component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./labs-inner/labs-inner.module').then(m => m.LabsInnerModule)
      },
    ]
  },
  /* {
    path: '3dmodel-list', component: ThreeDModelListComponent,
    //canActivate: [MainAuthGuard]
  },
  {
    path: '3dmodel-viewer', component: ThreeDModelViewerComponent,
    //canActivate: [MainAuthGuard]
  }, */ 
  {
    path: '', component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./app-inner/app-inner.module').then(m => m.AppInnerModule)
      },
    ]
  },
  {
    path: '', component: AuthLayoutComponent,
    canActivate: [NonAuthGuard],
    children: [
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }
    ]
  },
  //{path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
