<mat-toolbar class="main-header" color="white">
 
  <div class="branding">
    <div class="logo" (click)="getClickNavigate()"></div>
  </div>
  <div class="search-bar" fxFlex>
  
  </div>
  <button (click)="fullScreenToggle()" mat-icon-button>
    <mat-icon color="teric-icon">fullscreen</mat-icon>
  </button>
 
  <button mat-button [matMenuTriggerFor]="user" class="user-menu-trigger-mobile">
    <mat-icon color="teric-icon">person</mat-icon>&nbsp;<span style="font-size: 15px;font-weight: 500;">{{userName | titlecase}}</span>&nbsp;<mat-icon color="teric-icon" style="color: #6f6b6b !important;">arrow_drop_down</mat-icon>
  </button>
  
  <mat-menu #user="matMenu" x-position="before">

    <button mat-menu-item routerLink="/profile">
      <mat-icon color="teric-icon">account_box</mat-icon>
      Profile
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon color="teric-icon">exit_to_app</mat-icon>
      Sign Out
    </button>
  </mat-menu>
</mat-toolbar>
