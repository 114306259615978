import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import {Router} from '@angular/router';
import {DataService} from './data.service';
import {UntypedFormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AlertService} from './alert.service';
import {CoordsOptimiserService} from './coords-optimiser.service';
import {WebSocketsService} from './web-sockets.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Lightbox} from 'ngx-lightbox';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  s3Host = 'https://phyjc3yfv0.execute-api.ap-south-1.amazonaws.com/production/';

  constructor(public _auth: AuthService,
              public _api: ApiService,
              public _router: Router,
              public _data: DataService,
              public _lightbox: Lightbox,
              public _fb: UntypedFormBuilder,
              public _dialog: MatDialog,
              public _alert: AlertService,
              public _coordsOptimiser: CoordsOptimiserService,
              private snackBar: MatSnackBar,
              private _sanitizer: DomSanitizer,
              public _socket: WebSocketsService) {
  }

  public getRandomID(length) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  public generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public openModal(item) {
    const dialogRef = this._dialog.open(item, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  public closeDialog() {
    this._dialog.closeAll();
  }

  public gotoUrl(route: string, params?: any) {
    if (params) {
      this._router.navigate([route, params]);
    } else {
      this._router.navigateByUrl(route);
    }
  }

  getSanitizer() {
    return this._sanitizer;
  }

  getLastChars(string, length = 4) {
    return string.substr(string.length - length);
  }

  public navigateToUrlWithParams(url, query = null) {
    if (query) {
      this._router.navigate([url], {queryParams: query});
    } else {
      this._router.navigateByUrl(url);
    }
  }

  public createBlobUrlWithText(text) {
    const blob = new Blob([text]);
    // Obtain a blob URL reference to our worker 'file'.
    return window.URL.createObjectURL(blob); //// window.URL.revokeObjectURL(blobURL); remove
  }

  public getBytesInMB(bytes) {
    return bytes / (1024 * 1024);
  }

  formatSizeUnits(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  goBackInHistory() {
    history.back();
  }

  getPercentage(actual, total) {
    if (actual !== 0 && total !== 0) {
      return (+actual / +total) * 100;
    }
  }
}
