<!--START FOOTER ALTER-->
<div class="footer-alt">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-left pull-none">
                    <p class="copy-rights text-muted mb-3 mb-sm-0">&copy; {{ cur_year }} RoundSqr. All rights reserved</p>
                </div>
                
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
<!--START FOOTER ALTER-->

