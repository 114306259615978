import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TextNormalizePipe } from './pipes/textNormalize.pipe';
//import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
/* import { SocketIoModule } from 'ngx-socket-io'; */
import { AngularSplitModule } from 'angular-split';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CustomIconsModule } from './modules/custom-icons.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommentWrapperModule } from '../comments/comment-wrapper-module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStyleUrlPipe } from './pipes/safeStyleUrl.pipe';
/* import { TimeAgoPipe } from 'time-ago-pipe'; */
import { NetworkStatusAngularModule } from 'network-status-angular';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { NgChartsModule } from 'ng2-charts';
import { GraphComponent } from '../app-inner/graph/graph.component';
import { LightboxModule } from 'ngx-lightbox';
/* import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'; */
import { TimeAgoExtendsPipePipe } from './pipes/TimeAgoExtendsPipePipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from "ngx-spinner";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    NgChartsModule,
    CommonModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularSplitModule,
    KeyboardShortcutsModule.forRoot(),
    NetworkStatusAngularModule.forRoot(),
    NgxSpinnerModule
  ],
  exports: [
    NgxChartsModule,
    NgApexchartsModule,
    CustomIconsModule,
    KeyboardShortcutsModule,
    /* SocketIoModule, */
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    TimeAgoExtendsPipePipe,
    TextNormalizePipe,
    ShortNumberPipe,
    SafeHtmlPipe,
    SafeStyleUrlPipe,
    /* TimeAgoPipe, */
    GraphComponent,
    NotFoundComponent,
    LightboxModule,

    AngularSplitModule,
    NgxFileDropModule,
    NgMultiSelectDropDownModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    //MaterialTimePickerModule,
    Ng2GoogleChartsModule,
    MatRippleModule,
    MatTreeModule,
    CommentWrapperModule,
    NetworkStatusAngularModule,
    NgChartsModule,
    NgxSpinnerModule
    /*  SatDatepickerModule,
     SatNativeDateModule, */
  ],
  declarations: [
    GraphComponent,
    NotFoundComponent,
    TimeAgoExtendsPipePipe,
    TextNormalizePipe,
    SafeHtmlPipe,
    SafeStyleUrlPipe,
    ShortNumberPipe,
    /* TimeAgoPipe */
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
  ],
})
export class SharedModule {
}
