
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, EventEmitter, HostListener, Output, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';

import screenfull from 'screenfull';
import { CommonService } from 'src/app/shared/services/common.service';
import { ServerService } from 'src/app/shared/services/server.service';

const SMALL_WIDTH_BREAKPOINT = 960;

import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import Stats from 'three/examples/jsm/libs/stats.module.js';
import { GPUStatsPanel } from 'three/examples/jsm/utils/GPUStatsPanel.js';

@Component({
  selector: 'app-3dmodelviewer',
  templateUrl: './3dmodel-viewer.component.html',
  styleUrls: ['./3dmodel-viewer.component.scss']
})
export class ThreeDModelViewerComponent implements OnInit, OnDestroy {
  private _router: Subscription;

  mediaMatcher: MediaQueryList;
  url: string;
  sidePanelOpened;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  @ViewChild('sidemenu', { static: true }) sidemenu;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveScroll: PerfectScrollbarDirective;

  public config: PerfectScrollbarConfigInterface = {};
  userName: any;

  @Output()
  toggleSidenav = new EventEmitter<any>();

  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();

  @ViewChild('canvas')
  private canvasRef: ElementRef;

  //* Stage Properties

  @Input() public cameraZ: number = 5;

  @Input() public fieldOfView: number = 75;

  @Input('nearClipping') public nearClippingPlane: number = 0.1;

  @Input('farClipping') public farClippingPlane: number = 1000;

  //? Helper Properties (Private Properties);

  private camera!: THREE.PerspectiveCamera;

  private controls: OrbitControls;
  gui: any;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private loaderMTL = new MTLLoader();
  private loaderOBJ = new OBJLoader();

  private renderer!: THREE.WebGLRenderer;

  private scene!: THREE.Scene;

  //private stats = Stats();

  stats: any;
  gpuPanel: any;



  memoryDisplay;

  light_params = {
    lightX: - 1,
    lightY: - 1,
    lightZ: - 1,
  };

  constructor(
    private _server: ServerService,
    public _common: CommonService,
    private _element: ElementRef,
    private router: Router,
    zone: NgZone,
    private _mediaMatcher: MediaMatcher
  ) {
    this.onWindowResize = this.onWindowResize.bind(this);
    this.mediaMatcher = matchMedia(
      `(max-width: ${SMALL_WIDTH_BREAKPOINT - 100}px )`
    );



    //this._server.checkLogin();
  }

  openSideNav(open) {
    if (open === 'toggle') {
      this.sidemenu.toggle();
    } else if (open === 'open') {
      this.sidemenu.open();
    } else {
      this.sidemenu.close();
    }
  }

  ngOnInit(): void {

    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        document.querySelector(
          '.app-inner > .mat-drawer-content > div'
        ).scrollTop = 0;
        this.url = event.url;
        this.runOnRouteChange();
      });

    //console.log(localStorage.getItem('currentUser'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.name) {
      this.userName = currentUser.name;
    } else {
      this.userName = '';
    }
  }

  /**
     *create controls
     */
  private createControls = () => {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.enableZoom = true;
    this.controls.enablePan = true;
    this.controls.update();
  };

  /**
   * Create the scene
   */
  private createScene() {
    //* Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xd4d4d8);
    const axesHelper = new THREE.AxesHelper(5);
    axesHelper.visible = false;
    this.scene.add(axesHelper);

    //*Camera
    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    )

    this.camera.position.set(0, 2.5, 2.5); // Set position like this
    this.camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set look at coordinate like this

    const pointLight = new THREE.PointLight(0xffffff);
    pointLight.position.set(5, 5, 5);

    const color = 0xFFFFFF;
    const intensity = 1;

    const ambientLight = new THREE.AmbientLight(color, intensity);
    this.scene.add(ambientLight);

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(0, 1, 0); //default; light shining from top
    light.castShadow = true; // default false
    light.target.position.set(0, 0, 0);
    light.visible = false;
    this.scene.add(light);

    const cameraHelper = new THREE.CameraHelper(light.shadow.camera)
    cameraHelper.visible = false;
    this.scene.add(cameraHelper);

    const lightHelper = new THREE.PointLightHelper(pointLight, 15);
    lightHelper.visible = false;
    this.scene.add(lightHelper);

    const gridHelper = new THREE.GridHelper(200, 200);
    gridHelper.position.y = -0.5;
    gridHelper.position.x = -0.5;
    gridHelper.visible = false;
    this.scene.add(gridHelper);

    this.scene.add(this.camera);

    const material = new THREE.MeshPhongMaterial();
    material.color.setHSL(0, 1, .5);  // red
    material.color.set(0x00FFFF);
    material.flatShading = true;

    this.gui = new GUI();
    this.gui.addColor(new ColorGUIHelper(ambientLight, 'color'), 'value').name('color');
    this.gui.add(ambientLight, 'intensity', 0, 2, 0.01);
    this.gui.add(gridHelper, 'visible').name("GridPlane");
    this.gui.add(lightHelper, 'visible').name("LightPlan");
    this.gui.add(axesHelper, 'visible').name("AxisLine");


    /* const memory = gui.addFolder('Memory Info');
    this.memoryDisplay = memory.add(this.data, 'totalGPUMemory', "0 bytes"); */


    const cameraFolder = this.gui.addFolder("Camera")
    cameraFolder.add(this.camera.position, "x", 0, Math.PI * 2, 0.01).name('Camera X Axis');
    cameraFolder.add(this.camera.position, "y", 0, Math.PI * 2, 0.01).name('Camera Y Axis');
    cameraFolder.add(this.camera.position, "z", 0, Math.PI * 2, 0.01).name('Camera Z Axis');
    cameraFolder.close();

    const data = {
      color: light.color.getHex(),
      mapsEnabled: true,
    }

    const lightFolder = this.gui.addFolder("Light")
    lightFolder.add(light, 'visible').name("Light visible");
    lightFolder.add(cameraHelper, 'visible').name("Light Camera");
    lightFolder.addColor(data, 'color').onChange(() => {
      light.color.setHex(Number(data.color.toString().replace('#', '0x')))
    })
    lightFolder.add(light, 'intensity', 0, 1, 0.01)
    lightFolder.add(light.position, "x", -50, 50, 0.01).name('light direction x')
    lightFolder.add(light.position, "y", -50, 50, 0.01).name('light direction y');
    lightFolder.add(light.position, "z", -50, 50, 0.01).name('light direction z');
    lightFolder.close();


    this.loaderMTL.setPath('assets/threedmodel/textfile3/')
      .load('textured_output.mtl', (materials) => {

        materials.preload();
        this.loaderOBJ.setMaterials(materials)
          .setPath('assets/threedmodel/textfile3/')
          .load('textured_output.obj', (object) => {

            object.castShadow = true;
            object.receiveShadow = true;



            const objFolder = this.gui.addFolder("Rotate")
            objFolder.add(object.rotation, "x", 0, Math.PI * 2, 0.01).name('Rotate X Axis');
            objFolder.add(object.rotation, "y", 0, Math.PI * 2, 0.01).name('Rotate Y Axis');
            objFolder.add(object.rotation, "z", 0, Math.PI * 2, 0.01).name('Rotate Z Axis');
            objFolder.close();

            const scaleFolder = this.gui.addFolder("Scale")
            scaleFolder.add(object.scale, "x", 0, 2).name('Scale X Axis');
            scaleFolder.add(object.scale, "y", 0, 2).name('Scale Y Axis');
            scaleFolder.add(object.scale, "z", 0, 2).name('Scale Z Axis');
            scaleFolder.close();

            this.getChildObject(object);

            //object.position.y = 4;
            this.scene.add(object);
          }, (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
          }, (error) => {
            console.log("An error happened" + error)
          });
      },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        (error) => {
          console.log('An error happened')
        }
      );


    window.addEventListener('resize', this.onWindowResize, false);


  }

  getChildObject(object) {
    console.log(object);
    object.traverse((child) => {
      if ((child as THREE.Mesh).isMesh) {
        const m = child as THREE.Mesh
        m.receiveShadow = true
        m.castShadow = true;

        var wireframeMaterial = new THREE.LineBasicMaterial({
          color: 0xffffff
        });
        const materialParams = {
          boxMeshColor: wireframeMaterial.color.getHex(),
        };
        this.gui.add(child.material, 'wireframe');
        this.gui.addColor(materialParams, 'boxMeshColor')
          .onChange((value) => child.material.color.set(value));
      }
      if ((child as THREE.Light).isLight) {
        const l = child as THREE.Light
        l.castShadow = true
        l.shadow.bias = -0.003
        l.shadow.mapSize.width = 2048
        l.shadow.mapSize.height = 2048
      }


      /* if (child instanceof THREE.Mesh) {
        child.geometry.computeVertexNormals();
        //child.material.wireframe = false;
        var wireframeGeomtry = new THREE.WireframeGeometry(child.geometry);
        var wireframeMaterial = new THREE.LineBasicMaterial({
          color: 0xffffff
        });

        child.receiveShadow = true;
        child.castShadow = true;

        const materialParams = {
          boxMeshColor: wireframeMaterial.color.getHex(),
        };
        this.gui.add(child.material, 'wireframe');
        this.gui.addColor(materialParams, 'boxMeshColor')
          .onChange((value) => child.material.color.set(value));
      } */
    })
  }

  computeGPUMemory(mesh) {
    this.memoryDisplay.setValue(BufferGeometryUtils.estimateBytesUsed(mesh) + " bytes");
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  /**
 * Start the rendering loop
 */
  private startRenderingLoop() {
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.physicallyCorrectLights = true
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMapEnabled = true;

    this.stats = Stats();
    document.body.appendChild(this.stats.dom);
    this.stats.domElement.style.position = 'absolute';
    this.stats.domElement.style.left = '2px';
    this.stats.domElement.style.top = '85px';

    this.gpuPanel = new GPUStatsPanel(this.renderer.getContext());
    this.stats.addPanel(this.gpuPanel);
    this.stats.showPanel(0);

    let component: ThreeDModelViewerComponent = this;
    (function render() {
      requestAnimationFrame(render);
      component.gpuPanel.startQuery();
      component.renderer.render(component.scene, component.camera);
      component.stats.update();
      component.gpuPanel.endQuery();
    }());

  }

  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();
    this.createControls();

  }

  fullScreenToggle(): void {
    if (screenfull && screenfull.isEnabled) {
      screenfull.toggle();
      if (screenfull.isFullscreen) {
        console.log('open trigger');
        this.toggleSidenav.emit('open');
      } else {
        console.log('close trigger');
        this.toggleSidenav.emit('close');
      }
    }
  }

  logout() {
    this._common._api.post(this._common._api.ApiUrls().getLogout, {}).subscribe((response) => {
      this._common._alert.showAlert('Successfully Logged Out', 'Success');
      this._common._auth.logout();
      this._common._router.navigateByUrl('/login');
    }, (error) => {
      this._common._alert.showAlert('Something went wrong', 'Error');
    });
  }

  ngOnDestroy(): void {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }
    this.updatePS();
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages' ||
      this.url === '/apps/calendar' ||
      this.url === '/apps/media' ||
      this.url === '/maps/leaflet' ||
      this.url === '/taskboard'
      // ||
      // this.url.indexOf('/explore-inference') >= 0
    ) {
      return true;
    } else {
      return this.mediaMatcher.matches;
    }
  }




  menuMouseOver(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'side';
    }
  }

  updatePS(): void {
    if (!this.mediaMatcher.matches && !this.options.compact) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }
}

class ColorGUIHelper {
  object: any;
  prop: any;
  constructor(object, prop) {
    this.object = object;
    this.prop = prop;
  }
  get value() {
    return `#${this.object[this.prop].getHexString()}`;
  }
  set value(hexString) {
    this.object[this.prop].set(hexString);
  }
}
