import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public apiUrl;

  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _router: Router,
    private snackBar: MatSnackBar
  ) {
    this.apiUrl = environment.api_url;
  }

  /**
   * Api Urls
   */
  public ApiUrls() {
    return {
      userLogin: environment.api_url + "/account/login",
      getCheckLogin: environment.api_url + "/account/checkLogin",
      getLogout: environment.api_url + "/account/logout",
      getModelList: environment.api_url + "/model/list",
      getRequestList: environment.api_url + "/request/list",
      setAddModal: environment.api_url + "/modal/add",
      setEditModel: environment.api_url + "/model/edit",
      setDeleteModel: environment.api_url + "/model/delete",
      setUploadModelFile: environment.api_url + "/modal/uploadModelFile",
      setDeleteModelFile: environment.api_url + "/model/deleteModelFile",
      setDownloadModelFile: environment.api_url + "/model/downloadModelFile",
      setReUploadModelFile: environment.api_url + "/model/reuploadModelFile",
      getExperimentView: environment.api_url + "/experiment/view",
      getExperimentEdit: environment.api_url + "/experiment/edit",
      getExpListTrainRequest:
        environment.api_url + "/experiment/listTrainRequest",
      getExpViewTrainRequest:
        environment.api_url + "/experiment/viewTrainRequest",
      getExpUpgradeModel: environment.api_url + "/experiment/upgradeModel",
      getExpDeleteModel: environment.api_url + "/experiment/deleteModel",
      getExpDownloadModel: environment.api_url + "/experiment/downloadModel",
      getExperimentList: environment.api_url + "/experiment/list",
      getExpNewTrainRequest:
        environment.api_url + "/experiment/newTrainRequest",
      getExpDeleteTrainRequest:
        environment.api_url + "/experiment/deleteTrainRequest",
      getExperimentClassList: environment.api_url + "/experiment/class/list",
      downloadExportModel: environment.api_url + "/download/exportmodel",
      downloadListModels: environment.api_url + "/download/listmodels",
      getExpRequestAnnotationImage:
        environment.api_url + "/experiment/requestAnnotationImage",
      getExperimentHistory: environment.api_url + "/experiment/history",
      getExpClassHistory: environment.api_url + "/experiment/classhistory",
      setAddClassExperiment: environment.api_url + "/experiment/class/add",
      setDeleteClassExperiment:
        environment.api_url + "/experiment/class/delete",
      //'getAnnoClasslist': environment.api_url + '/class/list',
      getExperimentImages: environment.api_url + "/experiment/images",
      getExperimentDeleteImages:
        environment.api_url + "/experiment/deleteImage",
      getAnnoClassificationbulkedit:
        environment.api_url + "/annotation/classificationbulkedit",
      getExperimentDelete: environment.api_url + "/experiment/delete",
      getExpNewInferenceRequest:
        environment.api_url + "/experiment/newInferenceRequest",
      getExpExecuteInferenceRequest:
        environment.api_url + "/experiment/executeInferenceRequest",
      getExpExecuteClassification:
        environment.api_url + "/experiment/activelearningExecuteClassification",
      getExpExecuteClassificationInferenceReq:
        environment.api_url + "/experiment/newClassificationInferenceRequest",
      getExpExecuteDetectionInferenceReq:
        environment.api_url + "/experiment/newDetectionInferenceRequest",
      setExpListInferenceRequest:
        environment.api_url + "/experiment/listInferenceRequest",
      setSaveAnnotations: environment.api_url + "/annotation/saveAnnotations",
      getAnnotationList: environment.api_url + "/annotation/list",
      
      setAddAnnotation: environment.api_url + "/annotation/add",
      setAddSegmentation: environment.api_url + "/annotation/segmentation",

      setEditAnnotation: environment.api_url + "/annotation/edit",
      setDeleteAnnotation: environment.api_url + "/annotation/delete",
      setUpgradeAnnotation: environment.api_url + "/annotation/upgrade",
      setAllDeleteAnnotation: environment.api_url + "/annotation/deleteAll",
      getBreakupAnnotation: environment.api_url + "/annotation/breakup",
      getExportAnnotations:
        environment.api_url + "/experiment/exportAnnotations",
      setAddRequest: environment.api_url + "/request/add",
      setDeleteRequest: environment.api_url + "/request/delete",
      getTaskView: environment.api_url + "/task/view",
      getTaskImage: environment.api_url + "/task/getImage",
      getTaskImageHistory: environment.api_url + "/task/imageHistory",
      getExpListConfigs: environment.api_url + "/experiment/listConfigs",
      getImportListAnnotation: environment.api_url + "/annotation/listCsv",
      setCSVaddAnnotation: environment.api_url + "/annotation/csvadd",

      getClustersdropdown: environment.api_url + "/experiment/listClusters",
      setExpforaddtdamapper: environment.api_url + "/experiment/addtdamapper",
      getExpforlistTDA: environment.api_url + "/experiment/listTda",
      setExpfortdamapper: environment.api_url + "/experiment/tdamapper",

      //graph
      getModelGraph: environment.api_url + "/model/modelgraph",

      //Task
      getTaskClassificationImageHistory:
        environment.api_url + "/task/ClassificationImagehistory",
      setAnnoClassificationbulkedit:
        environment.api_url + "/annotation/Classificationbulkedit",
      getTaskClassificationImages:
        environment.api_url + "/task/getClassificationImages",
      setAnnoClassificationbulkadd:
        environment.api_url + "/annotation/ClassificationBulkAdd",

      getExploreInference: environment.api_url + "/explore/inference",
      getExploreTest: environment.api_url + "/explore/test",
      getPolygonPredictor: environment.api_url + "/predictor/getPolygon",
      getSmartPolygonPredictor:
        environment.api_url + "/predictor/getSmartPolygon",
      getListComment: environment.api_url + "/comment/listComment",
      getCredentialList: environment.api_url + "/credential/list",
      setAddCredential: environment.api_url + "/credential/add",
      setEditCredential: environment.api_url + "/credential/edit",
      setDeleteCredential: environment.api_url + "/credential/delete",
      getCompanyListGroup: environment.api_url + "/company/listGroup",
      getDatasetView: environment.api_url + "/dataset/view",
      getDatasetListImages: environment.api_url + "/dataset/listImages",
      getDatasetList: environment.api_url + "/dataset/list",
      getDatasetDeleteImages: environment.api_url + "/dataset/deleteImage",
      getDatasetBulkImageExists:
        environment.api_url + "/dataset/bulkImageExists",
      getDatasetUploadSingleImage:
        environment.api_url + "/dataset/uploadSingleImage",
      getDatasetImportFromS3: environment.api_url + "/dataset/importFromS3",
      setDatasetEdit: environment.api_url + "/dataset/edit",
      setDatasetDelete: environment.api_url + "/dataset/delete",
      setAddDataset: environment.api_url + "/dataset/add",
      getViewDeviceor: environment.api_url + "/deviceor/view",
      getDeviceorOpenCamera: environment.api_url + "/deviceor/openCamera",
      getDeviceorCloseCamera: environment.api_url + "/deviceor/closeCamera",
      setStrategyDeviceor: environment.api_url + "/deviceor/setStrategy",
      getDeviceorList: environment.api_url + "/deviceor/list",
      getViewExplore: environment.api_url + "/explore/view",
      getExploreList: environment.api_url + "/explore/list",
      getProfile: environment.api_url + "/account/getProfile",
      editProfile: environment.api_url + "/account/editProfile",
      setChangePassword: environment.api_url + "/account/changePassword",
      setUploadProfilePicture:
        environment.api_url + "/account/uploadProfilePicture",
      getViewProject: environment.api_url + "/project/view",
      getDeleteProject: environment.api_url + "/project/delete",
      getProjectList: environment.api_url + "/project/list",
      getViewModel: environment.api_url + "/model/view",
      setAddComment: environment.api_url + "/comment/addComment",
      setEditUser: environment.api_url + "/user/edit",
      getUserListLite: environment.api_url + "/user/listlite",
      getTaskList: environment.api_url + "/task/list",
      setDeleteTask: environment.api_url + "/task/delete",
      setAddTask: environment.api_url + "/task/add",
      getUserList: environment.api_url + "/user/list",
      getTeamList: environment.api_url + "/team/list",
      setEditTeam: environment.api_url + "/team/edit",
      getClassBreakUp: environment.api_url + "/dataset/classBreakUp",
      getExpClassBreakUp: environment.api_url + "/experiment/classBreakUp",
      getExpDatapointUnlink:
        environment.api_url + "/experiment/datapoint/unlink",
      getExpDatapointLink: environment.api_url + "/experiment/datapoint/link",
      getExpDatapointMerge: environment.api_url + "/experiment/datapoint/merge",
      getExpDatapointClassificationMerge:
        environment.api_url + "/experiment/datapoint/Classificationmerge",
      getExpListLite: environment.api_url + "/experiment/listLite",
      setUploadModelFile_model: environment.api_url + "/model/uploadModelFile",
      setUploadCSVFile_annotation:
        environment.api_url + "/annotation/uploadCsv",
    };
  }

  private getOptions(overrideHeaders = {}, overrideOptions = {}) {
    const commonHeaders = {
      Authorization: "Bearer " + this._auth.token,
      "Content-Type": "application/json",
    };
    const commonOptions = { observe: "response" };
    commonOptions["headers"] = { ...commonHeaders, ...overrideHeaders };
    return { ...commonOptions, ...overrideOptions };
  }

  private getAuthOptions(overrideOptions = {}) {
    const commonHeaders = {
      Authorization: "Bearer " + this._auth.token,
    };
    const commonOptions = { observe: "response" };
    commonOptions["headers"] = { ...commonHeaders };
    return { ...commonOptions, ...overrideOptions };
  }

  public getLoginAuthorization = function (val) {
    val = btoa(val);

    let authorization = {
      Authorization: "Bearer " + val,
      "Content-Type": "application/json",
    };
    return authorization;
  };

  public post(url, data, headers = {}, options = {}): Observable<any> {
    options = this.getOptions(headers, options);
    return this._http.post(url, data, options).pipe(
      map((response: any) => {
        if (response.status === 201) {
          this.showAlert(response.body.msg, "Success", 1000);
        } else if (response.status === 203) {
          this.showAlert(response.body.msg, "Warning");
        } else if (response.status === 204) {
          this.showAlert(response.body.msg, "Info");
        }
        //console.log(response.body);
        return response.body;
      }),
      catchError((err: any) => {
        console.error("Tap Error:", err);
        this.handleErrors(err.error);

        if (err.status === 401) {
          this._auth.logout();
          this._router.navigateByUrl("/homepage");
          //this._router.navigateByUrl('/login');
        }
        return throwError(err.error);
      })
    );
  }

  public upload(url, data, headers = {}, options = {}): Observable<any> {
    options = this.getAuthOptions(options);
    return this._http.post(url, data, options).pipe(
      map((response: any) => {
        // console.log(response.status);
        return response;
      }),
      catchError((err: any) => {
        console.error("Tap Error:", err);
        this.handleErrors(err.error);
        if (err.status === 401) {
          this._auth.logout();
          //this._router.navigateByUrl('/');
          this._router.navigateByUrl("/homepage");
        }
        return throwError(err.error);
      })
    );
  }

  public get(url, data = {}, headers = {}, options = {}): Observable<any> {
    options = this.getOptions(headers, options);
    url = this.prepareParams(url, data);
    return this._http.get(url, options).pipe(
      map((response: any) => {
        if (response.status === 201) {
          this.showAlert(response.body.msg, "Success");
        } else if (response.status === 203) {
          this.showAlert(response.body.msg, "Warning");
        } else if (response.status === 204) {
          this.showAlert(response.body.msg, "Info");
        }
        //console.log(response.body);
        return response.body;
      }),
      catchError((err: any) => {
        console.error("Tap Error:", err.error);
        //this.handleErrors(err.error);
        if (err.status === 401) {
          this._auth.logout();
          //this._router.navigateByUrl('/');
          this._router.navigateByUrl("/homepage");
        }
        return throwError(err.error);
      })
    );
  }

  public put(url, data = {}, headers = {}, options = {}): Observable<any> {
    options = this.getOptions(headers, options);
    return this._http.put(url, data, options).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError((err: any) => {
        console.error("Tap Error:", err.error);
        this.handleErrors(err.error);
        if (err.status === 401) {
          this._auth.logout();
          this._router.navigateByUrl("/homepage");
          //this._router.navigateByUrl('/login');
        }
        return throwError(err.error);
      })
    );
  }

  public delete(url, data = {}, headers = {}, options = {}): Observable<any> {
    options = this.getOptions(headers, options);
    url = this.prepareParams(url, data);
    return this._http.delete(url, options).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError((err: any) => {
        console.error("Tap Error:", err.error);
        this.handleErrors(err.error);
        if (err.status === 401) {
          this._auth.logout();
          this._router.navigateByUrl("/homepage");
          //this._router.navigateByUrl('/login');
        }
        return throwError(err.error);
      })
    );
  }

  prepareParams(url, data): String {
    if (data.toString().length === 2 || data.toString() === "[object Object]") {
      return url;
    }
    const params = $.param(data);
    if (url.indexOf("?") !== -1) {
      url += +"&" + params;
    } else {
      url += "?" + params;
    }
    return url;
  }

  showAlert(message: string, action: string, duration = 2500) {
    this.snackBar.open(message, action, {
      duration: duration,
      verticalPosition: "bottom",
      horizontalPosition: "right",
    });
  }

  private handleErrors(data: any) {
    if (data.errors) {
      for (const error in data.errors) {
        if (data.errors.hasOwnProperty(error)) {
          this.showAlert(data.errors[error], "error");
        }
      }
    } else {
      if ((data.error && data.error !== "") || (data.msg && data.msg !== "")) {
        //this.showAlert(data.error || data.msg, 'error');
        //console.log(data.error || data.msg, 'error')
      } else {
        this.showAlert("Oops... Something went wrong!", "error");
      }
    }
  }
}
