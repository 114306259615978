import {MenuService} from './menu.service';
import {Component} from '@angular/core'; 
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  providers: [MenuService]
})
export class MenuComponent {
  public config: PerfectScrollbarConfigInterface = {};
  constructor(
    public menuService: MenuService
  ) {} 
}
