<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar1">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo text-uppercase" routerLink="/">
            <img style="height: 36px;" src="assets/images/logo.svg" class="login-logo-text" alt="Zastra"/>
        </a>

        <button class="navbar-toggler" (click)="toggleMenu()" type="button" id="menu_button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" scrollSpy>
            <ul class="navbar-nav navbar-center" id="mySidenav">
                <li class="nav-item">
                    <a data="id_home"  [ngxScrollTo]="'#home'" class="nav-link">Home</a>
                </li>
                <li class="nav-item">
                    <a data="id_features"  [ngxScrollTo]="'#features'" class="nav-link">Cognitive Data</a>
                </li>
                <li class="nav-item">
                    <a data="id_services"  [ngxScrollTo]="'#services'" class="nav-link">Structured Data</a>
                </li>
                
            </ul>
            <div class="nav-button ml-auto">
                <!-- <ul class="nav navbar-nav navbar-right">
                    <li>
                        <button type="button" routerLink="/login" class="btn btn-custom navbar-btn btn-rounded waves-effect waves-light">Login</button>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</nav>