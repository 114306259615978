import { RoleGuard } from './shared/guards/role.guard';
import { CommonService } from './shared/services/common.service';
import { NgModule } from '@angular/core';
import { SocketService } from './shared/services/socket.service';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './shared/guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { DataService } from './shared/services/data.service';
import { AppRoutingModule } from './app.routing.module';
import { ThemeModule } from './layout/theme.module';
import { SharedModule } from './shared/shared.module';
import { ApiService } from './shared/services/api.service';
import { AppComponent } from './app.component';
import { AuthService } from './shared/services/auth.service';
import { ServerService } from './shared/services/server.service';
import { AlertService } from './shared/services/alert.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NonAuthGuard } from './shared/guards/non-auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { CoordsOptimiserService } from './shared/services/coords-optimiser.service';
import { WebSocketsService } from './shared/services/web-sockets.service';
import { LandLayoutModule } from './layout/land-layout/layout/layout.module';
import { MainDashboardLayoutComponent } from './layout/maindashboard-layout/maindashboard-layout.component';
import { MainAuthGuard } from './shared/guards/main-auth.guard';
import { ThreeDModelListComponent } from './layout/threedmodel-layout/3dmodel-list.component';
import { ThreeDModelViewerComponent } from './layout/threedmodel-layout/3dmodel-viewer/3dmodel-viewer.component';


@NgModule({
  declarations: [
    AppComponent,
    MainDashboardLayoutComponent,
    ThreeDModelListComponent,
    ThreeDModelViewerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    SharedModule,
    ThemeModule,
    LandLayoutModule
  ],
  providers: [
    // App Services
    AuthService, DataService, CommonService, ServerService, ApiService,
    AlertService, SocketService, CoordsOptimiserService, WebSocketsService,

    // Route Guards
    AuthGuard, NonAuthGuard, RoleGuard, MainAuthGuard
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
